import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Avatar, 
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  LinearProgress,
  styled,
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from '@mui/material';
import { EmojiEvents, Search, Close } from '@mui/icons-material';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

interface RankingProps {
  showOnlyTop5?: boolean;
}

interface RankingUser {
  id: number;
  nome: string;
  points: number;
  taxaAcerto: number;
  nivel: string;
  totalApostas: number;
  total_rodadas: number;
  time_escolhido: string;
  nome_bolao: string;
  show_ranking_numbers: boolean;
}

interface Bolao {
  id: number;
  nome_bolao: string;
}

// Styled components
const RankingAvatar = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: '0.9rem'
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 2,
  [`&.MuiLinearProgress-colorPrimary`]: {
    backgroundColor: theme.palette.grey[100],
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 2,
    background: 'linear-gradient(90deg, #2196F3 0%, #1976D2 100%)',
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 800,
  maxHeight: '70vh',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  padding: theme.spacing(3),
  overflow: 'auto'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 500,
  padding: theme.spacing(1.5),
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const Ranking: React.FC<RankingProps> = ({ showOnlyTop5 = false }) => {
  const { user } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBolao, setSelectedBolao] = useState<string>('');
  const [boloes, setBoloes] = useState<Bolao[]>([]);
  const [rankings, setRankings] = useState<RankingUser[]>([]);
  const [completeRankings, setCompleteRankings] = useState<RankingUser[]>([]);
  const [filteredRankings, setFilteredRankings] = useState<RankingUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('@BetMax:token');
    if (token) {
      fetchRankings();
      fetchBoloes();
    } else {
      setError('Por favor, faça login para ver o ranking.');
    }
  }, []);

  useEffect(() => {
    filterRankings();
  }, [selectedBolao, searchTerm, completeRankings]);

  const filterRankings = () => {
    if (!completeRankings.length) return;

    let filtered = [...completeRankings];

    // Filtrar por bolão
    if (selectedBolao) {
      filtered = filtered.filter(rank => rank.nome_bolao === selectedBolao);
    }

    // Filtrar por nome
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase().trim();
      filtered = filtered.filter(rank => 
        rank.nome.toLowerCase().includes(searchTermLower)
      );
    }

    setFilteredRankings(filtered);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
    // Resetar os filtros ao abrir o modal
    setSearchTerm('');
    setSelectedBolao('');
  };

  const handleModalClose = () => {
    setOpenModal(false);
    // Resetar os filtros ao fechar o modal
    setSearchTerm('');
    setSelectedBolao('');
  };

  const fetchBoloes = async () => {
    try {
      const response = await api.get('/bolao/boloes-com-apostas');
      if (Array.isArray(response.data)) {
        setBoloes(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar bolões:', error);
    }
  };

  const fetchRankings = async () => {
    try {
      setLoading(true);
      setError('');
      
      const token = localStorage.getItem('@BetMax:token');
      if (!token) {
        setError('Por favor, faça login para ver o ranking.');
        setLoading(false);
        return;
      }

      // Busca o top 5 para o card principal
      const responseTop5 = await api.get('/bolao/ranking/top5');
      
      // Busca o ranking completo para o modal
      const responseCompleto = await api.get('/bolao/ranking/completo');
      
      if (Array.isArray(responseTop5.data)) {
        const formattedRankingsTop5: RankingUser[] = responseTop5.data.map((item: any) => ({
          id: item.user_id,
          nome: item.user_name,
          points: item.pontos || 0,
          taxaAcerto: item.taxa_acerto || 0,
          nivel: determinarNivel(item.pontos || 0),
          totalApostas: item.total_apostas || 0,
          total_rodadas: item.total_rodadas || 0,
          time_escolhido: item.time_escolhido || 'Não definido',
          nome_bolao: item.nome_bolao || 'Não definido',
          show_ranking_numbers: item.show_ranking_numbers
        }));
        
        setRankings(formattedRankingsTop5);
      }

      if (Array.isArray(responseCompleto.data)) {
        const formattedRankingsCompleto: RankingUser[] = responseCompleto.data.map((item: any) => ({
          id: item.user_id,
          nome: item.user_name,
          points: item.pontos || 0,
          taxaAcerto: item.taxa_acerto || 0,
          nivel: determinarNivel(item.pontos || 0),
          totalApostas: item.total_apostas || 0,
          total_rodadas: item.total_rodadas || 0,
          time_escolhido: item.time_escolhido || 'Não definido',
          nome_bolao: item.nome_bolao || 'Não definido',
          show_ranking_numbers: item.show_ranking_numbers
        }));
        
        setCompleteRankings(formattedRankingsCompleto);
        setFilteredRankings(formattedRankingsCompleto);
      }
    } catch (err: any) {
      console.error('Erro ao buscar ranking:', err);
      if (err.response?.status === 401) {
        setError('Sessão expirada. Por favor, faça login novamente.');
      } else if (err.response) {
        setError(err.response.data.message || 'Erro ao carregar o ranking.');
      } else if (err.request) {
        setError('Não foi possível conectar ao servidor.');
      } else {
        setError('Erro ao processar a requisição.');
      }
      setRankings([]);
      setCompleteRankings([]);
    } finally {
      setLoading(false);
    }
  };

  const determinarNivel = (pontos: number): string => {
    if (pontos >= 30) return 'Expert';
    if (pontos >= 20) return 'Avançado';
    if (pontos >= 10) return 'Intermediário';
    return 'Iniciante';
  };

  const maxPoints = Math.max(...rankings.map(r => r.points));

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      {/* Ranking Header */}
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EmojiEvents 
            sx={{ 
              color: 'primary.main',
              fontSize: 20
            }} 
          />
          <Typography variant="subtitle1" fontWeight="600">
            Top 5 Ranking
          </Typography>
        </Box>
        <Button
          size="small"
          onClick={handleModalOpen}
          sx={{ 
            textTransform: 'none',
            fontSize: '0.875rem'
          }}
        >
          Ver mais
        </Button>
      </Box>

      {/* Ranking List - Top 5 */}
      <List sx={{ px: 0 }}>
        {rankings.slice(0, 5).map((user, index) => (
          <ListItem
            key={user.id}
            sx={{
              px: 1.5,
              py: 1,
              mb: 1,
              bgcolor: 'background.paper',
              borderRadius: 1.5,
              border: '1px solid',
              borderColor: 'divider',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: 'primary.main',
                transform: 'translateX(4px)',
              },
            }}
          >
            <ListItemAvatar sx={{ minWidth: 40 }}>
              <Box sx={{ position: 'relative' }}>
                <Avatar sx={{ width: 32, height: 32 }}>
                  {user.nome.charAt(0)}
                </Avatar>
                {rankings.length > 0 ? rankings[0].show_ranking_numbers && (
                  <Avatar
                    sx={{
                      position: 'absolute',
                      top: -6,
                      left: -6,
                      width: 16,
                      height: 16,
                      fontSize: '0.75rem',
                      bgcolor: 'primary.main',
                      border: '1px solid #fff',
                      boxShadow: '0 1px 2px rgba(0,0,0,0.2)'
                    }}
                  >
                    {index + 1}
                  </Avatar>
                ) : null}
              </Box>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 0.5 }}>
                  <Box>
                    <Typography variant="body2" fontWeight="600" noWrap sx={{ maxWidth: '120px' }}>
                      {user.nome}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      color="text.secondary" 
                      sx={{ 
                        fontSize: '0.7rem',
                        display: 'block'
                      }}
                    >
                      Time: {user.time_escolhido}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Typography 
                      variant="body2" 
                      color="primary" 
                      fontWeight="600"
                      sx={{
                        px: 1,
                        py: 0.25,
                        bgcolor: 'primary.50',
                        borderRadius: 1,
                        fontSize: '0.75rem'
                      }}
                    >
                      {user.points} pts
                    </Typography>
                    <Typography 
                      variant="caption" 
                      color="text.secondary" 
                      sx={{ 
                        fontSize: '0.7rem',
                        mt: 0.5
                      }}
                    >
                      {user.total_rodadas} rodadas
                    </Typography>
                  </Box>
                </Box>
              }
              secondary={
                <Box sx={{ mt: 0.5 }}>
                  <LinearProgress
                    variant="determinate"
                    value={(user.points / maxPoints) * 100}
                    sx={{
                      height: 4,
                      borderRadius: 2,
                      bgcolor: 'action.hover',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 2,
                      }
                    }}
                  />
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>

      {/* Modal com lista completa e filtros */}
      <Modal
        open={openModal}
        onClose={handleModalClose}
      >
        <Box sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 800,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
          maxHeight: '90vh',
          overflow: 'auto'
        }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            height: '100%'
          }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              mb: 2 
            }}>
              <Typography variant="h6">Ranking Completo</Typography>
              <IconButton onClick={handleModalClose} size="small">
                <Close />
              </IconButton>
            </Box>

            {/* Filtros */}
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              mb: 2,
              flexDirection: { xs: 'column', sm: 'row' }
            }}>
              <TextField
                size="small"
                placeholder="Buscar por nome..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{ flex: 1 }}
              />
              
              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel>Filtrar por Bolão</InputLabel>
                <Select
                  value={selectedBolao}
                  label="Filtrar por Bolão"
                  onChange={(e) => setSelectedBolao(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {boloes.map((bolao) => (
                    <MenuItem key={bolao.id} value={bolao.nome_bolao}>
                      {bolao.nome_bolao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Tabela com scroll */}
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Posição</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Time Escolhido</TableCell>
                    <TableCell>Bolão</TableCell>
                    <TableCell align="center">Pontos</TableCell>
                    <TableCell align="center">Taxa de Acerto</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRankings.map((rank, index) => (
                    <TableRow 
                      key={`${rank.id}-${rank.nome_bolao}`}
                      sx={{ 
                        bgcolor: rank.id === Number(user?.id) ? 'action.selected' : 'inherit',
                        '&:nth-of-type(odd)': { bgcolor: 'action.hover' }
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Avatar sx={{ width: 24, height: 24 }}>{rank.nome.charAt(0)}</Avatar>
                          <Typography variant="body2">{rank.nome}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {rank.time_escolhido}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {rank.nome_bolao}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2" color="primary.main" fontWeight="600">
                          {rank.points}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2" color="success.main">
                          {rank.taxaAcerto}%
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Ranking;
