import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Alert,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Tabs,
  Tab,
  Chip,
  Tooltip,
  Divider,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  SportsSoccer as SportsIcon,
  Schedule as ScheduleIcon,
  EmojiEvents as TrophyIcon,
  Group as TeamIcon,
} from '@mui/icons-material';
import api from '../services/api';

interface Championship {
  id: number;
  nome: string;
  ano: number;
  tipo: 'Serie A' | 'Serie B';
  status: string;
  total_times?: number;
  total_partidas?: number;
  partidas_realizadas?: number;
}

type MatchStatus = 'AGENDADO' | 'EM_ANDAMENTO' | 'FINALIZADO';

interface Match {
  id: number;
  campeonato_id: number;
  time_casa_id: number;
  time_visitante_id: number;
  time_casa: string;
  time_casa_icone: string;
  time_visitante: string;
  time_visitante_icone: string;
  rodada: number;
  data: string;
  status: MatchStatus;
  placar_casa: number | null;
  placar_visitante: number | null;
}

const Championship: React.FC = () => {
  const [championships, setChampionships] = useState<Championship[]>([]);
  const [matches, setMatches] = useState<Match[]>([]);
  const [filteredMatches, setFilteredMatches] = useState<Match[]>([]);
  const [searchTeam, setSearchTeam] = useState('');
  const [selectedRound, setSelectedRound] = useState<string>('');
  const [selectedChampionship, setSelectedChampionship] = useState<Championship | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openMatchDialog, setOpenMatchDialog] = useState(false);
  const [nome, setNome] = useState('');
  const [ano, setAno] = useState(new Date().getFullYear());
  const [tipo, setTipo] = useState<'Serie A' | 'Serie B'>('Serie A');
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
  const [placarCasa, setPlacarCasa] = useState('');
  const [placarVisitante, setPlacarVisitante] = useState('');
  const [dataPartida, setDataPartida] = useState('');
  const [snackbar, setSnackbar] = useState({ 
    open: false, 
    message: '', 
    severity: 'success' as 'success' | 'error' 
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  useEffect(() => {
    loadChampionships();
  }, []);

  useEffect(() => {
    if (selectedChampionship) {
      loadMatches(selectedChampionship.id);
    }
  }, [selectedChampionship]);

  useEffect(() => {
    if (matches.length > 0) {
      let filtered = [...matches];
      
      // Filtrar por nome do time
      if (searchTeam) {
        const searchLower = searchTeam.toLowerCase();
        filtered = filtered.filter(match => 
          match.time_casa.toLowerCase().includes(searchLower) || 
          match.time_visitante.toLowerCase().includes(searchLower)
        );
      }
      
      // Filtrar por rodada
      if (selectedRound) {
        filtered = filtered.filter(match => match.rodada === parseInt(selectedRound));
      }
      
      setFilteredMatches(filtered);
    } else {
      setFilteredMatches([]);
    }
  }, [matches, searchTeam, selectedRound]);

  const loadChampionships = async () => {
    try {
      const response = await api.get('/matches/championships');
      if (response.data.success) {
        setChampionships(response.data.data);
        // Só seleciona o primeiro campeonato se nenhum estiver selecionado
        if (response.data.data.length > 0 && !selectedChampionship) {
          setSelectedChampionship(response.data.data[0]);
        }
      }
    } catch (error) {
      console.error('Erro ao carregar campeonatos:', error);
      showSnackbar('Erro ao carregar campeonatos', 'error');
    }
  };

  const loadMatches = async (championshipId: number) => {
    try {
      setLoading(true);
      const response = await api.get(`/matches/championship/${championshipId}/matches`);
      if (response.data.success) {
        setMatches(response.data.data);
        setFilteredMatches(response.data.data);
        
        // Extrair rodadas únicas para o filtro usando reduce
        const uniqueRounds = response.data.data.reduce((acc: number[], match: Match) => {
          if (!acc.includes(match.rodada)) {
            acc.push(match.rodada);
          }
          return acc;
        }, []).sort((a: number, b: number) => a - b);

        if (uniqueRounds.length > 0 && !selectedRound) {
          setSelectedRound(String(uniqueRounds[0]));
        }
      }
    } catch (error) {
      console.error('Erro ao carregar partidas:', error);
      showSnackbar('Erro ao carregar partidas', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateChampionship = async () => {
    if (!nome.trim()) {
      showSnackbar('Por favor, insira o nome do campeonato', 'error');
      return;
    }

    setLoading(true);
    try {
      const response = await api.post('/matches/championship', {
        nome,
        ano,
        tipo,
      });

      if (response.data.success) {
        showSnackbar('Campeonato criado com sucesso!', 'success');
        setOpenDialog(false);
        await loadChampionships(); // Aguardar o carregamento dos campeonatos
        const newChampionshipId = response.data.data.championshipId;
        const newChampionship = (await api.get('/matches/championships')).data.data.find(
          (c: Championship) => c.id === newChampionshipId
        );
        if (newChampionship) {
          setSelectedChampionship(newChampionship);
          await loadMatches(newChampionshipId);
        }
      }
    } catch (error) {
      console.error('Erro ao criar campeonato:', error);
      showSnackbar('Erro ao criar campeonato', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateMatch = async () => {
    if (!selectedMatch) return;

    try {
      const response = await api.put(`/matches/match/${selectedMatch.id}`, {
        placar_casa: parseInt(placarCasa),
        placar_visitante: parseInt(placarVisitante),
        status: 'FINALIZADO'
      });

      if (response.data.success) {
        showSnackbar('Partida atualizada com sucesso!', 'success');
        setOpenMatchDialog(false);
        if (selectedChampionship) {
          loadMatches(selectedChampionship.id);
        }
      }
    } catch (error) {
      showSnackbar('Erro ao atualizar partida', 'error');
    }
  };

  const handleEditMatch = async (match: Match) => {
    try {
      const response = await api.put(`/matches/match/${match.id}`, {
        placar_casa: match.placar_casa,
        placar_visitante: match.placar_visitante,
        status: match.status,
        data: match.data
      });

      if (response.data.success) {
        // Atualiza a lista de partidas
        if (selectedChampionship) {
          loadMatches(selectedChampionship.id);
        }
        setEditDialogOpen(false);
        setSelectedMatch(null);
        showSnackbar('Partida atualizada com sucesso!', 'success');
      }
    } catch (error) {
      console.error('Erro ao atualizar partida:', error);
      showSnackbar('Erro ao atualizar partida', 'error');
    }
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNome('');
    setAno(new Date().getFullYear());
    setTipo('Serie A');
  };

  const handleCloseMatchDialog = () => {
    setOpenMatchDialog(false);
    setSelectedMatch(null);
    setPlacarCasa('');
    setPlacarVisitante('');
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getStatusColor = (status: MatchStatus) => {
    switch (status) {
      case 'AGENDADO': return '#2196f3';
      case 'EM_ANDAMENTO': return '#ff9800';
      case 'FINALIZADO': return '#4caf50';
      default: return '#757575';
    }
  };

  const getStatusLabel = (status: MatchStatus) => {
    switch (status) {
      case 'AGENDADO': return 'Agendado';
      case 'EM_ANDAMENTO': return 'Em Andamento';
      case 'FINALIZADO': return 'Finalizado';
      default: return status;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1a237e' }}>
          Gerenciamento de Campeonatos
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{
            bgcolor: '#1a237e',
            '&:hover': { bgcolor: '#0d47a1' }
          }}
        >
          Novo Campeonato
        </Button>
      </Box>

      <Grid container spacing={3}>
        {championships.map((championship) => (
          <Grid item xs={12} md={6} lg={4} key={championship.id}>
            <Card 
              sx={{ 
                height: '100%',
                cursor: 'pointer',
                transition: 'transform 0.2s',
                '&:hover': { transform: 'translateY(-4px)' },
                border: selectedChampionship?.id === championship.id ? '2px solid #1a237e' : 'none'
              }}
              onClick={() => setSelectedChampionship(championship)}
            >
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {championship.nome}
                  </Typography>
                  <Chip
                    label={championship.tipo}
                    color={championship.tipo === 'Serie A' ? 'primary' : 'secondary'}
                    size="small"
                  />
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <TeamIcon color="action" />
                      <Typography variant="body2">
                        {championship.total_times || 20} Times
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <SportsIcon color="action" />
                      <Typography variant="body2">
                        {championship.partidas_realizadas || 0}/{championship.total_partidas || 380} Jogos
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    {championship.ano}
                  </Typography>
                  <Chip
                    label={championship.status}
                    size="small"
                    sx={{
                      bgcolor: getStatusColor(championship.status as MatchStatus),
                      color: 'white'
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {selectedChampionship && (
        <Box sx={{ mt: 4 }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label="Partidas" icon={<SportsIcon />} />
              <Tab label="Classificação" icon={<TrophyIcon />} />
            </Tabs>

            {tabValue === 0 && (
              <>
                <Box sx={{ p: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
                  <TextField
                    label="Buscar Time"
                    variant="outlined"
                    size="small"
                    value={searchTeam}
                    onChange={(e) => setSearchTeam(e.target.value)}
                    sx={{ minWidth: 200 }}
                    InputProps={{
                      startAdornment: (
                        <TeamIcon sx={{ color: 'action.active', mr: 1 }} />
                      ),
                    }}
                  />
                  <FormControl size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>Rodada</InputLabel>
                    <Select
                      value={selectedRound}
                      label="Rodada"
                      onChange={(e) => setSelectedRound(e.target.value)}
                    >
                      <MenuItem value="">Todas</MenuItem>
                      {Array.from(new Set(matches.map(m => m.rodada))).sort((a, b) => a - b).map((rodada) => (
                        <MenuItem key={rodada} value={rodada}>
                          Rodada {rodada}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Rodada</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell align="right">Time Casa</TableCell>
                        <TableCell align="center">Placar</TableCell>
                        <TableCell align="left">Time Visitante</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredMatches.map((match) => (
                        <TableRow key={match.id} hover>
                          <TableCell>{match.rodada}</TableCell>
                          <TableCell>
                            {match.data ? new Date(match.data).toLocaleString('pt-BR', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            }) : 'Data não definida'}
                          </TableCell>
                          <TableCell align="right">
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                              {match.time_casa}
                              <img 
                                src={match.time_casa_icone} 
                                alt={`Ícone ${match.time_casa}`} 
                                style={{ width: 24, height: 24, objectFit: 'contain' }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            {match.placar_casa !== null && match.placar_visitante !== null ? (
                              `${match.placar_casa} x ${match.placar_visitante}`
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <img 
                                src={match.time_visitante_icone} 
                                alt={`Ícone ${match.time_visitante}`} 
                                style={{ width: 24, height: 24, objectFit: 'contain' }}
                              />
                              {match.time_visitante}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={getStatusLabel(match.status)}
                              size="small"
                              sx={{
                                bgcolor: getStatusColor(match.status),
                                color: 'white'
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Editar Partida">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setSelectedMatch(match);
                                  setPlacarCasa(match.placar_casa?.toString() || '');
                                  setPlacarVisitante(match.placar_visitante?.toString() || '');
                                  setDataPartida(match.data || '');
                                  setEditDialogOpen(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

            {tabValue === 1 && (
              <Box sx={{ p: 3 }}>
                <Typography variant="h6">
                  Classificação em desenvolvimento...
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      )}

      {/* Dialog de Criar Campeonato */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Novo Campeonato</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Nome do Campeonato"
              type="text"
              fullWidth
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              required
              sx={{ mb: 2 }}
            />
            
            <TextField
              label="Ano"
              type="number"
              fullWidth
              value={ano}
              onChange={(e) => setAno(Number(e.target.value))}
              required
              inputProps={{ min: 2024, max: 2030 }}
              sx={{ mb: 2 }}
            />
            
            <FormControl fullWidth required>
              <InputLabel>Tipo do Campeonato</InputLabel>
              <Select
                value={tipo}
                label="Tipo do Campeonato"
                onChange={(e: SelectChangeEvent) => setTipo(e.target.value as 'Serie A' | 'Serie B')}
              >
                <MenuItem value="Serie A">Série A</MenuItem>
                <MenuItem value="Serie B">Série B</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button 
            onClick={handleCreateChampionship}
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Criar'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de Editar Partida */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Editar Partida</DialogTitle>
        <DialogContent>
          {selectedMatch && (
            <Box sx={{ pt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    {selectedMatch.time_casa} vs {selectedMatch.time_visitante}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Data e Hora"
                    type="datetime-local"
                    value={dataPartida ? dataPartida.slice(0, 16) : ''}
                    onChange={(e) => setDataPartida(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <img 
                      src={selectedMatch.time_casa_icone} 
                      alt={`Ícone ${selectedMatch.time_casa}`} 
                      style={{ width: 24, height: 24, objectFit: 'contain' }}
                    />
                    <Typography variant="subtitle1">{selectedMatch.time_casa}</Typography>
                  </Box>
                  <TextField
                    label="Placar Casa"
                    type="number"
                    value={placarCasa}
                    onChange={(e) => setPlacarCasa(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h6">x</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <img 
                      src={selectedMatch.time_visitante_icone} 
                      alt={`Ícone ${selectedMatch.time_visitante}`} 
                      style={{ width: 24, height: 24, objectFit: 'contain' }}
                    />
                    <Typography variant="subtitle1">{selectedMatch.time_visitante}</Typography>
                  </Box>
                  <TextField
                    label="Placar Visitante"
                    type="number"
                    value={placarVisitante}
                    onChange={(e) => setPlacarVisitante(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={selectedMatch.status}
                      onChange={(e) => setSelectedMatch({
                        ...selectedMatch,
                        status: e.target.value as MatchStatus
                      })}
                    >
                      <MenuItem value="AGENDADO">Agendado</MenuItem>
                      <MenuItem value="EM_ANDAMENTO">Em Andamento</MenuItem>
                      <MenuItem value="FINALIZADO">Finalizado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancelar</Button>
          <Button 
            onClick={() => selectedMatch && handleEditMatch({
              ...selectedMatch,
              placar_casa: placarCasa ? parseInt(placarCasa) : null,
              placar_visitante: placarVisitante ? parseInt(placarVisitante) : null,
              data: dataPartida,
              status: selectedMatch.status
            })}
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Championship;
