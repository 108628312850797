import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import VideoPlayer from './VideoPlayer';
import MatchList from './MatchList';
import { LiveMatch } from './types';
import api from '../../services/api';

const WatchLive: React.FC = () => {
  const [liveMatches, setLiveMatches] = useState<LiveMatch[]>([]);
  const [selectedMatchId, setSelectedMatchId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLiveMatches = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await api.get('/matches/live');
        const data = response.data;
        
        if (data.success && data.data && data.data.length > 0) {
          const formattedMatches: LiveMatch[] = data.data.map((match: any) => ({
            id: match.id.toString(),
            homeTeam: {
              id: match.time_casa_id,
              name: match.time_casa,
              logo: match.time_casa_icone || 'https://via.placeholder.com/60'
            },
            awayTeam: {
              id: match.time_visitante_id,
              name: match.time_visitante,
              logo: match.time_visitante_icone || 'https://via.placeholder.com/60'
            },
            league: match.campeonato || 'Campeonato',
            videoUrl: match.video_url ? `${process.env.REACT_APP_API_URL}${match.video_url}` : 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            thumbnailUrl: match.thumbnail_url ? `${process.env.REACT_APP_API_URL}${match.thumbnail_url}` : 'https://example.com/thumbnail.jpg',
            status: match.status,
            round: match.rodada,
            year: match.ano || new Date().getFullYear(),
            homeScore: match.placar_casa,
            awayScore: match.placar_visitante
          }))
          // Ordenar jogos em andamento primeiro
          .sort((a: LiveMatch, b: LiveMatch) => {
            if (a.status === 'em_andamento' && b.status !== 'em_andamento') return -1;
            if (a.status !== 'em_andamento' && b.status === 'em_andamento') return 1;
            return 0;
          });
          
          setLiveMatches(formattedMatches);
          setSelectedMatchId(formattedMatches[0].id);
        } else {
          // Se não houver partidas, deixa a lista vazia
          setLiveMatches([]);
          setSelectedMatchId(null);
        }
      } catch (err) {
        console.error('Erro ao buscar partidas:', err);
        setError('Erro ao carregar partidas');
        setLiveMatches([]);
        setSelectedMatchId(null);
      } finally {
        setLoading(false);
      }
    };

    fetchLiveMatches();
  }, []);

  const selectedMatch = liveMatches.find(match => match.id === selectedMatchId) || null;

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100%',
        p: 2 
      }}>
        <Typography>Carregando partidas...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100%',
        p: 2 
      }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      gap: 2,
      p: 2,
      height: 'calc(100vh - 120px)',
    }}>
      <Box sx={{
        display: 'flex',
        gap: 2,
        height: '100%',
      }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Player de Vídeo */}
          <VideoPlayer match={selectedMatch} />

          {/* Aviso de Contribuição */}
          <Box sx={{
            border: '1px solid',
            borderColor: 'primary.light',
            color: 'text.primary',
            p: 2.5,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1.5
          }}>
            <InfoIcon sx={{ color: 'primary.main' }} />
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
                Contribuição por Jogo: R$ 2,00
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Sua contribuição é fundamental para a manutenção do site. Agradecemos seu apoio.
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Lista de Jogos */}
        <MatchList 
          matches={liveMatches}
          selectedMatchId={selectedMatchId}
          onSelectMatch={(match) => setSelectedMatchId(match.id)}
        />
      </Box>
    </Box>
  );
};

export default WatchLive;
