import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Stack,
  Divider,
  Avatar
} from '@mui/material';
import { 
  TrendingUp,
  AttachMoney,
  EmojiEvents,
  Schedule
} from '@mui/icons-material';
import api from '../../services/api';

interface Bet {
  id: number;
  amount: number;
  status: string;
  data_aposta: string;
  data_hora: string;
  time_casa: string;
  time_casa_icone: string;
  time_visitante: string;
  time_visitante_icone: string;
  placar_casa: number | null;
  placar_visitante: number | null;
  partida_status: string;
}

const MyBets: React.FC = () => {
  const [bets, setBets] = useState<Bet[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBets = async () => {
      try {
        setLoading(true);
        const response = await api.get('/bolao/minhas-apostas');
        if (response.data.success) {
          setBets(response.data.data);
        }
      } catch (err) {
        console.error('Erro ao buscar apostas:', err);
        setError('Erro ao carregar suas apostas');
      } finally {
        setLoading(false);
      }
    };

    fetchBets();
  }, []);

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'ganhou':
        return 'success';
      case 'perdeu':
        return 'error';
      default:
        return 'warning';
    }
  };

  // Cálculos para o resumo
  const totalApostado = bets.reduce((sum, bet) => sum + bet.amount, 0);
  const potentialWinnings = totalApostado * 2; // Valor exemplo, ajuste conforme sua regra de negócio
  const apostasAtivas = bets.filter(bet => bet.status.toLowerCase() === 'pendente').length;
  const apostasGanhas = bets.filter(bet => bet.status.toLowerCase() === 'ganhou').length;

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Cards de Resumo */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <AttachMoney color="primary" />
              <Box>
                <Typography color="text.secondary" variant="body2">
                  Total Apostado
                </Typography>
                <Typography variant="h6">
                  R$ {totalApostado.toFixed(2)}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <TrendingUp color="success" />
              <Box>
                <Typography color="text.secondary" variant="body2">
                  Possível Retorno
                </Typography>
                <Typography variant="h6">
                  R$ {potentialWinnings.toFixed(2)}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Schedule color="info" />
              <Box>
                <Typography color="text.secondary" variant="body2">
                  Apostas Ativas
                </Typography>
                <Typography variant="h6">
                  {apostasAtivas}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <EmojiEvents color="warning" />
              <Box>
                <Typography color="text.secondary" variant="body2">
                  Apostas Ganhas
                </Typography>
                <Typography variant="h6">
                  {apostasGanhas}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      {/* Lista de Apostas */}
      <Grid container spacing={2}>
        {bets.length === 0 ? (
          <Grid item xs={12}>
            <Paper sx={{ p: 3, textAlign: 'center' }}>
              <Typography>
                Você ainda não fez nenhuma aposta
              </Typography>
            </Paper>
          </Grid>
        ) : (
          bets.map((bet) => (
            <Grid item xs={12} md={6} lg={4} key={bet.id}>
              <Card>
                <CardContent>
                  <Stack spacing={2}>
                    {/* Status da Aposta */}
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Chip
                        label={bet.status}
                        color={getStatusColor(bet.status)}
                        size="small"
                      />
                      <Typography variant="caption" color="text.secondary">
                        {new Date(bet.data_aposta).toLocaleDateString('pt-BR')}
                      </Typography>
                    </Box>

                    {/* Detalhes do Jogo */}
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                        <Avatar src={bet.time_casa_icone} sx={{ width: 40, height: 40, mr: 1 }} />
                        <Typography variant="body1" noWrap>{bet.time_casa}</Typography>
                      </Box>
                      
                      {bet.partida_status === 'em_andamento' && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
                          <Typography variant="h6">
                            {bet.placar_casa ?? 0} - {bet.placar_visitante ?? 0}
                          </Typography>
                        </Box>
                      )}
                      
                      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'flex-end' }}>
                        <Typography variant="body1" noWrap sx={{ mr: 1 }}>{bet.time_visitante}</Typography>
                        <Avatar src={bet.time_visitante_icone} sx={{ width: 40, height: 40 }} />
                      </Box>
                    </Stack>

                    <Divider />

                    {/* Detalhes da Aposta */}
                    <Stack spacing={1}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2" color="text.secondary">
                          Valor Apostado
                        </Typography>
                        <Typography variant="body2">
                          R$ {bet.amount.toFixed(2)}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2" color="text.secondary">
                          Possível Retorno
                        </Typography>
                        <Typography variant="body2" color="success.main" fontWeight="bold">
                          R$ {(bet.amount * 2).toFixed(2)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default MyBets;
