import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL && process.env.REACT_APP_PORT_API 
  ? `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT_API}`
  : '';

if (!apiUrl) {
  throw new Error('REACT_APP_API_URL não está definida');
}

// Evento customizado para controlar loading
export const loadingEvents = {
  start: new Event('api-loading-start'),
  end: new Event('api-loading-end')
};

// Configuração da API
const api = axios.create({
  baseURL: `${apiUrl}/api`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Interceptor para adicionar o token em todas as requisições
api.interceptors.request.use(
  (config) => {
    // Dispara evento de início do loading
    window.dispatchEvent(loadingEvents.start);
    return config;
  },
  (error) => {
    // Dispara evento de fim do loading em caso de erro
    window.dispatchEvent(loadingEvents.end);
    return Promise.reject(error);
  }
);

// Interceptor para adicionar o token em todas as requisições
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('@BetMax:token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Interceptor para respostas
api.interceptors.response.use(
  (response) => {
    // Dispara evento de fim do loading
    window.dispatchEvent(loadingEvents.end);
    return response;
  },
  (error) => {
    console.error('API Error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      config: {
        url: error.config?.url,
        baseURL: error.config?.baseURL,
        method: error.config?.method,
        fullUrl: `${error.config?.baseURL}${error.config?.url}`
      }
    });
    // Dispara evento de fim do loading em caso de erro
    window.dispatchEvent(loadingEvents.end);
    return Promise.reject(error);
  }
);

export default api;
