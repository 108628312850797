import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
  TextField,
  MenuItem,
  InputAdornment,
  Tooltip,
  Stack,
  Avatar
} from '@mui/material';
import { 
  Search,
  FilterList,
  Stadium,
  Schedule,
  SportsSoccer,
  EmojiEvents,
  Sort
} from '@mui/icons-material';
import api from '../../services/api';
import { AxiosError } from 'axios';

interface GamesProps {
  showAllGames?: boolean;
}

interface Game {
  id: number;
  partida_id: number;
  time_casa: string;
  time_casa_icone?: string;
  placar_casa?: number;
  time_visitante: string;
  time_visitante_icone?: string;
  placar_visitante?: number;
  data: string;
  status: string;
  campeonato?: string;
  rodada: number;
  valor_entrada: number;
  premio_total: number;
  total_apostadores: number;
  total_vencedores: number;
}

const Games: React.FC<GamesProps> = ({ showAllGames = false }) => {
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentRound, setCurrentRound] = useState(1);
  const [maxRound, setMaxRound] = useState(1);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        setLoading(true);
        setError(null);
        
        console.log('Buscando jogos...');
        const response = await api.get(showAllGames ? '/matches' : '/matches/today');
        
        console.log('Resposta da API:', response.data);
        
        if (response.data.success) {
          const processedGames = response.data.data.map((game: any) => ({
            ...game,
            campeonato: game.campeonato || 'Campeonato Brasileiro'
          }));

          // Encontrar a última rodada com data menor ou igual a hoje
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const latestRound = Math.max(
            ...processedGames
              .filter((game: Game) => new Date(game.data) <= today)
              .map((game: Game) => game.rodada)
          );

          setMaxRound(Math.max(...processedGames.map((game: Game) => game.rodada)));
          setCurrentRound(latestRound);
          setGames(processedGames);
        } else {
          console.log('Nenhum jogo encontrado:', response.data.message);
          setError(response.data.message);
        }
      } catch (err) {
        console.error('Erro ao buscar jogos:', err);
        if (err instanceof AxiosError) {
          console.error('Erro da API:', err.response?.data);
          setError(err.response?.data?.message || 'Erro ao carregar jogos');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [showAllGames]);

  // Filtrar jogos pela rodada atual
  const filteredGames = games
    .filter((game: Game) => game.rodada === currentRound)
    .sort((a: Game, b: Game) => new Date(a.data).getTime() - new Date(b.data).getTime());

  const paginatedGames = filteredGames.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Função para determinar a cor do status
  const getStatusColor = (status: string | undefined) => {
    if (!status) return 'default';
    
    switch (status.toLowerCase()) {
      case 'agendado':
        return 'info';
      case 'em_andamento':
        return 'warning';
      case 'encerrado':
        return 'success';
      default:
        return 'default';
    }
  };

  // Função para formatar o status para exibição
  const formatStatus = (status: string | undefined) => {
    if (!status) return 'Não definido';
    
    switch (status.toLowerCase()) {
      case 'agendado':
        return 'Agendado';
      case 'em_andamento':
        return 'Em andamento';
      case 'encerrado':
        return 'Encerrado';
      default:
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Filtros */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            select
            size="small"
            label="Rodada"
            value={currentRound}
            onChange={(e) => setCurrentRound(Number(e.target.value))}
            sx={{ width: 150 }}
          >
            {Array.from({ length: maxRound }, (_, i) => i + 1).map((round) => (
              <MenuItem key={round} value={round}>
                Rodada {round}
              </MenuItem>
            ))}
          </TextField>

          <Box sx={{ flex: 1 }} />

          <Typography variant="subtitle2" color="text.secondary">
            {filteredGames[0]?.campeonato} - {new Date(filteredGames[0]?.data).getFullYear()}
          </Typography>
        </Stack>
      </Paper>

      {/* Tabela */}
      <TableContainer component={Paper} sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 200 }} align="right">Casa</TableCell>
              <TableCell sx={{ width: 100 }} align="center">Placar</TableCell>
              <TableCell sx={{ minWidth: 200 }}>Visitante</TableCell>
              <TableCell sx={{ minWidth: 150 }}>Data/Hora</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">Carregando...</TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ color: 'error.main' }}>
                  {error}
                </TableCell>
              </TableRow>
            ) : paginatedGames.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Nenhum jogo encontrado para esta rodada
                </TableCell>
              </TableRow>
            ) : (
              paginatedGames.map((game: Game) => (
                <TableRow
                  key={game.id}
                  sx={{
                    backgroundColor: game.status === 'em_andamento' ? '#fff8e1' : 'inherit',
                    '&:hover': {
                      backgroundColor: game.status === 'em_andamento' ? '#fff3c4' : '#f5f5f5'
                    }
                  }}
                >
                  <TableCell align="right">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                      <Typography>{game.time_casa}</Typography>
                      {game.time_casa_icone && (
                        <Avatar
                          src={game.time_casa_icone}
                          alt={game.time_casa}
                          sx={{ width: 24, height: 24 }}
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {game.status === 'em_andamento' || game.placar_casa !== undefined ? (
                      `${game.placar_casa ?? 0} x ${game.placar_visitante ?? 0}`
                    ) : (
                      'x'
                    )}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {game.time_visitante_icone && (
                        <Avatar
                          src={game.time_visitante_icone}
                          alt={game.time_visitante}
                          sx={{ width: 24, height: 24 }}
                        />
                      )}
                      <Typography>{game.time_visitante}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {new Date(game.data).toLocaleString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={game.status === 'em_andamento' ? 'AO VIVO' : formatStatus(game.status)}
                      color={getStatusColor(game.status)}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredGames.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Jogos por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </Box>
  );
};

export default Games;
