import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Box,
  Badge,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  InputAdornment,
} from '@mui/material';
import {
  SportsSoccer,
  AttachMoney,
  Help,
  MenuBook,
  Settings,
  ExitToApp,
  Person,
  Notifications,
  AccountBalance,
  CreditCard,
  EmojiEvents,
  ManageAccounts,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

interface TopMenuProps {
  rightComponent?: React.ReactNode;
}

const TopMenu: React.FC<TopMenuProps> = ({ rightComponent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState<null | HTMLElement>(null);
  const [openCreditDialog, setOpenCreditDialog] = useState(false);
  const [creditAmount, setCreditAmount] = useState('');

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenNotifications = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const handleNavigation = (path: string) => {
    console.log('Navigating to:', path); // Debug log
    navigate(path);
  };

  const handleOpenCredit = () => {
    setOpenCreditDialog(true);
  };

  const handleCloseCredit = () => {
    setOpenCreditDialog(false);
    setCreditAmount('');
  };

  const handleAddCredit = () => {
    // Aqui você implementaria a lógica de processamento do crédito
    console.log('Processando crédito de R$', creditAmount);
    handleCloseCredit();
  };

  const menuItems = [
    { path: '/dashboard/jogos', text: 'Jogos', icon: <SportsSoccer /> },
    { path: '/dashboard/bolao', text: 'Bolão', icon: <EmojiEvents /> },
    { path: '/dashboard/ajuda', text: 'Ajuda', icon: <Help /> },
  ];

  const notifications = [
    'Novo jogo disponível!',
    'Seu palpite foi registrado com sucesso.',
  ];

  return (
    <AppBar position="fixed" sx={{ 
      zIndex: (theme) => theme.zIndex.drawer + 1, 
      background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
    }}>
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 0,
            marginRight: 4,
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
          onClick={() => handleNavigation('/dashboard')}
        >
          BetMax
        </Typography>

        <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
          {menuItems.map((item) => (
            <Button
              key={item.path}
              onClick={() => handleNavigation(item.path)}
              color="inherit"
              startIcon={item.icon}
              sx={{
                textTransform: 'none',
                color: location.pathname === item.path ? 'yellow' : 'white',
              }}
            >
              {item.text}
            </Button>
          ))}
        </Box>

        {rightComponent && (
          <Box sx={{ ml: 'auto' }}>
            {rightComponent}
          </Box>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleOpenCredit}
            sx={{ 
              color: 'white', 
              textTransform: 'none',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: 'success.dark'
              }
            }}
            startIcon={<AttachMoney />}
          >
            Inserir Crédito
          </Button>

          <Button
            sx={{ 
              color: 'white', 
              textTransform: 'none',
              fontWeight: 500,
              border: '1px solid rgba(255,255,255,0.23)',
              '&:hover': {
                border: '1px solid rgba(255,255,255,0.5)'
              }
            }}
            startIcon={<AttachMoney />}
          >
            R$ 100,00
          </Button>

          <IconButton
            color="inherit"
            onClick={handleOpenNotifications}
          >
            <Badge badgeContent={notifications.length} color="error">
              <Notifications />
            </Badge>
          </IconButton>

          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ padding: 0.5 }}
          >
            <Avatar
              alt={user?.name || 'User'}
              src={user?.avatar}
              sx={{ width: 32, height: 32 }}
            />
          </IconButton>
        </Box>

        {/* Notifications Menu */}
        <Menu
          anchorEl={anchorElNotifications}
          open={Boolean(anchorElNotifications)}
          onClose={handleCloseNotifications}
          onClick={handleCloseNotifications}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {notifications.map((notification, index) => (
            <MenuItem key={index} onClick={handleCloseNotifications}>
              {notification}
            </MenuItem>
          ))}
        </Menu>

        {/* User Menu */}
        <Menu
          sx={{ mt: '45px' }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={() => { navigate('/profile'); handleCloseUserMenu(); }}>
            <ListItemIcon><Person fontSize="small" /></ListItemIcon>
            Perfil
          </MenuItem>
          <MenuItem onClick={() => { navigate('/dashboard/championship'); handleCloseUserMenu(); }}>
            <ListItemIcon><ManageAccounts fontSize="small" /></ListItemIcon>
            Gerenciar Campeonatos
          </MenuItem>
          <MenuItem onClick={() => { navigate('/settings'); handleCloseUserMenu(); }}>
            <ListItemIcon><Settings fontSize="small" /></ListItemIcon>
            Configurações
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => { handleLogout(); handleCloseUserMenu(); }}>
            <ListItemIcon><ExitToApp fontSize="small" /></ListItemIcon>
            Sair
          </MenuItem>
        </Menu>

        {/* Credit Dialog */}
        <Dialog open={openCreditDialog} onClose={handleCloseCredit}>
          <DialogTitle>Inserir Crédito</DialogTitle>
          <DialogContent>
            <Alert severity="info" sx={{ mb: 2, mt: 2 }}>
              <Typography variant="body2" component="div">
                <strong>Informações importantes:</strong>
                <ul style={{ margin: '8px 0', paddingLeft: '20px' }}>
                  <li>Depósitos via PIX são processados em até 5 minutos</li>
                  <li>Cartão de crédito: aprovação imediata</li>
                  <li>Boleto bancário: até 1 dia útil após o pagamento</li>
                  <li>Valor mínimo de depósito: R$ 20,00</li>
                </ul>
              </Typography>
            </Alert>
            <TextField
              autoFocus
              margin="dense"
              label="Valor do Crédito"
              type="number"
              fullWidth
              value={creditAmount}
              onChange={(e) => setCreditAmount(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              helperText="Digite o valor que deseja depositar"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCredit} color="primary">
              Cancelar
            </Button>
            <Button 
              onClick={handleAddCredit} 
              color="primary" 
              disabled={!creditAmount || parseFloat(creditAmount) < 20}
              startIcon={<CreditCard />}
            >
              Adicionar
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
