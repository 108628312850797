import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Link,
  Paper,
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { SportsSoccer, EmojiEvents, TrendingUp } from '@mui/icons-material';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/dashboard');
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      {/* Lado Esquerdo - Imagem e Mensagens */}
      <Grid
        item
        xs={false}
        sm={6}
        sx={{
          backgroundImage: 'url(https://www.alagoasdiario.com.br/wp-content/uploads/2023/09/Bola-na-Rede-Aposta.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(26, 35, 126, 0.90)',
            backdropFilter: 'blur(4px)',
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            p: 6,
            width: '100%',
            maxWidth: '500px',
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
              mb: 2
            }}
          >
            BetMax
          </Typography>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center' }}>
              <SportsSoccer sx={{ fontSize: 45, color: '#64b5f6' }} />
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5, letterSpacing: 0.5 }}>
                  Aposte com Confiança
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.9, lineHeight: 1.4 }}>
                  As melhores odds dos campeonatos
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center' }}>
              <EmojiEvents sx={{ fontSize: 45, color: '#64b5f6' }} />
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5, letterSpacing: 0.5 }}>
                  Ganhe Prêmios
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.9, lineHeight: 1.4 }}>
                  Acumule pontos e troque por recompensas
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center' }}>
              <TrendingUp sx={{ fontSize: 45, color: '#64b5f6' }} />
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5, letterSpacing: 0.5 }}>
                  Acompanhe Resultados
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.9, lineHeight: 1.4 }}>
                  Estatísticas em tempo real
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>

      {/* Lado Direito - Formulário */}
      <Grid
        item
        xs={12}
        sm={6}
        component={Paper}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          bgcolor: '#f8f9fa',
        }}
      >
        <Box
          sx={{
            p: 4,
            width: '100%',
            maxWidth: 400,
            mx: 'auto',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 4,
              color: '#1a237e',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Bem-vindo
          </Typography>

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ mb: 3 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                py: 1.5,
                mb: 3,
                backgroundColor: '#1a237e',
                '&:hover': {
                  backgroundColor: '#0d47a1',
                },
                fontSize: '1.1rem',
              }}
            >
              Entrar
            </Button>

            <Box sx={{ textAlign: 'center' }}>
              <Link
                href="/registro"
                variant="body1"
                sx={{
                  color: '#1a237e',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Não tem uma conta? Cadastre-se
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
