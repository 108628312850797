import React from 'react';
import { Box } from '@mui/material';
import BoloesDisponiveis from '../../../components/dashboard/BoloesDisponiveis';
import TopMenu from '../../../components/dashboard/TopMenu';

const BolaoPage: React.FC = () => {
  return (
    <>
      <TopMenu />
      <Box sx={{ 
        flexGrow: 1,
        bgcolor: '#f8f9fa',
        p: 3,
        mt: 8
      }}>
        <BoloesDisponiveis />
      </Box>
    </>
  );
};

export default BolaoPage;
