import React from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { streams } from '../../data/streams';

interface StreamSelectorProps {
  onStreamSelect: (url: string) => void;
}

const StreamSelector: React.FC<StreamSelectorProps> = ({ onStreamSelect }) => {
  const handleChange = (event: SelectChangeEvent) => {
    onStreamSelect(event.target.value);
  };

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <Select
        defaultValue=""
        onChange={handleChange}
        displayEmpty
        sx={{
          backgroundColor: 'white',
          '& .MuiSelect-select': {
            py: 1.5
          }
        }}
      >
        <MenuItem value="" disabled>
          Selecione um canal
        </MenuItem>
        {streams.map((stream) => (
          <MenuItem key={stream.url} value={stream.url}>
            {stream.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StreamSelector;
