import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';

interface User {
  id: string;
  name: string;
  email: string;
  saldo: number;
  points: number;
  avatar?: string;
  referralCode: string;
  referredBy?: string;
}

interface RegisterData {
  name: string;
  email: string;
  password: string;
  referralCode?: string;
}

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (data: RegisterData) => Promise<void>;
  logout: () => Promise<void>;
  updateUser: (data: Partial<User>) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  // Função para verificar e configurar autenticação
  const checkAuth = async () => {
    const token = localStorage.getItem('@BetMax:token');
    const storedUser = localStorage.getItem('@BetMax:user');

    if (token && storedUser) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser(JSON.parse(storedUser));
    }
  };

  useEffect(() => {
    checkAuth().finally(() => setLoading(false));
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await api.post('/auth/login', { email, password });
      
      const { token, user } = response.data;
      
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem('@BetMax:token', token);
      localStorage.setItem('@BetMax:user', JSON.stringify(user));
      
      setUser(user);
    } catch (error: any) {
      console.error('Erro detalhado no login:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        config: {
          baseURL: error.config?.baseURL,
          url: error.config?.url,
          method: error.config?.method
        }
      });
      throw error;
    }
  };

  const register = async (data: RegisterData) => {
    try {
      const response = await api.post('/auth/register', data);
      const { token, user } = response.data;

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem('@BetMax:token', token);
      localStorage.setItem('@BetMax:user', JSON.stringify(user));
      
      setUser(user);
    } catch (error) {
      console.error('Erro no registro:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      delete api.defaults.headers.common['Authorization'];
      localStorage.removeItem('@BetMax:token');
      localStorage.removeItem('@BetMax:user');
      setUser(null);
    } catch (error) {
      console.error('Erro no logout:', error);
    }
  };

  const updateUser = async (data: Partial<User>) => {
    try {
      const response = await api.put('/users/profile', data);
      const updatedUser = response.data;
      
      localStorage.setItem('@BetMax:user', JSON.stringify(updatedUser));
      setUser(updatedUser);
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: !!user,
        loading,
        login,
        register,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
