export interface Stream {
  name: string;
  url: string;
}

export const streams: Stream[] = [
  // Esportes
  {
    name: "ESPN",
    url: "https://reidoscanais.tv/embed/?id=espn"
  },
  {
    name: "ESPN 2",
    url: "https://reidoscanais.tv/embed/?id=espn2"
  },
  {
    name: "ESPN 3",
    url: "https://reidoscanais.tv/embed/?id=espn3"
  },
  {
    name: "ESPN 4",
    url: "https://reidoscanais.tv/embed/?id=espn4"
  },
  {
    name: "ESPN EXTRA",
    url: "https://reidoscanais.tv/embed/?id=espnextra"
  },
  {
    name: "SPORTV",
    url: "https://reidoscanais.tv/embed/?id=sportv"
  },
  {
    name: "SPORTV 2",
    url: "https://reidoscanais.tv/embed/?id=sportv2"
  },
  {
    name: "SPORTV 3",
    url: "https://reidoscanais.tv/embed/?id=sportv3"
  },
  {
    name: "TNT SPORTS",
    url: "https://reidoscanais.tv/embed/?id=tntsports"
  },
  {
    name: "PREMIERE CLUBES",
    url: "https://reidoscanais.tv/embed/?id=premiereclubes"
  },
  {
    name: "PREMIERE 2",
    url: "https://reidoscanais.tv/embed/?id=premiere2"
  },
  {
    name: "PREMIERE 3",
    url: "https://reidoscanais.tv/embed/?id=premiere3"
  },
  {
    name: "PREMIERE 4",
    url: "https://reidoscanais.tv/embed/?id=premiere4"
  },
  {
    name: "PREMIERE 5",
    url: "https://reidoscanais.tv/embed/?id=premiere5"
  },
  {
    name: "PREMIERE 6",
    url: "https://reidoscanais.tv/embed/?id=premiere6"
  },
  {
    name: "PREMIERE 7",
    url: "https://reidoscanais.tv/embed/?id=premiere7"
  },
  {
    name: "PREMIERE 8",
    url: "https://reidoscanais.tv/embed/?id=premiere8"
  },
  {
    name: "BAND SPORTS",
    url: "https://reidoscanais.tv/embed/?id=bandsports"
  },
  {
    name: "FOX SPORTS 2",
    url: "https://reidoscanais.tv/embed/?id=foxsports2"
  },
  {
    name: "COMBATE",
    url: "https://reidoscanais.tv/embed/?id=combate"
  },
  // Filmes e Séries
  {
    name: "HBO",
    url: "https://reidoscanais.tv/embed/?id=hbo"
  },
  {
    name: "HBO 2",
    url: "https://reidoscanais.tv/embed/?id=hbo2"
  },
  {
    name: "HBO PLUS",
    url: "https://reidoscanais.tv/embed/?id=hboplus"
  },
  {
    name: "HBO FAMILY",
    url: "https://reidoscanais.tv/embed/?id=hbofamily"
  },
  {
    name: "HBO XTREME",
    url: "https://reidoscanais.tv/embed/?id=hboxtreme"
  },
  {
    name: "HBO MUNDI",
    url: "https://reidoscanais.tv/embed/?id=hbomundi"
  },
  {
    name: "HBO POP",
    url: "https://reidoscanais.tv/embed/?id=hbopop"
  },
  {
    name: "TELECINE PREMIUM",
    url: "https://reidoscanais.tv/embed/?id=telecinepremium"
  },
  {
    name: "TELECINE ACTION",
    url: "https://reidoscanais.tv/embed/?id=telecineaction"
  },
  {
    name: "TELECINE TOUCH",
    url: "https://reidoscanais.tv/embed/?id=telecinetouch"
  },
  {
    name: "TELECINE FUN",
    url: "https://reidoscanais.tv/embed/?id=telecinefun"
  },
  {
    name: "TELECINE PIPOCA",
    url: "https://reidoscanais.tv/embed/?id=telecinepipoca"
  },
  {
    name: "TELECINE CULT",
    url: "https://reidoscanais.tv/embed/?id=telecinecult"
  },
  {
    name: "SPACE",
    url: "https://reidoscanais.tv/embed/?id=space"
  },
  {
    name: "TNT",
    url: "https://reidoscanais.tv/embed/?id=tnt"
  },
  {
    name: "TNT SERIES",
    url: "https://reidoscanais.tv/embed/?id=tntseries"
  },
  {
    name: "MEGAPIX",
    url: "https://reidoscanais.tv/embed/?id=megapix"
  },
  {
    name: "AXN",
    url: "https://reidoscanais.tv/embed/?id=axn"
  },
  {
    name: "CINEMAX",
    url: "https://reidoscanais.tv/embed/?id=cinemax"
  },
  {
    name: "PARAMOUNT",
    url: "https://reidoscanais.tv/embed/?id=paramount"
  },
  {
    name: "WARNER",
    url: "https://reidoscanais.tv/embed/?id=warner"
  },
  {
    name: "UNIVERSAL",
    url: "https://reidoscanais.tv/embed/?id=universal"
  },
  {
    name: "SONY",
    url: "https://reidoscanais.tv/embed/?id=sony"
  },
  {
    name: "FX",
    url: "https://reidoscanais.tv/embed/?id=fx"
  },
  // Documentários
  {
    name: "DISCOVERY CHANNEL",
    url: "https://reidoscanais.tv/embed/?id=discovery"
  },
  {
    name: "DISCOVERY TURBO",
    url: "https://reidoscanais.tv/embed/?id=discoveryturbo"
  },
  {
    name: "DISCOVERY H&H",
    url: "https://reidoscanais.tv/embed/?id=discoveryhh"
  },
  {
    name: "DISCOVERY SCIENCE",
    url: "https://reidoscanais.tv/embed/?id=discoveryscience"
  },
  {
    name: "DISCOVERY THEATER",
    url: "https://reidoscanais.tv/embed/?id=discoverytheater"
  },
  {
    name: "DISCOVERY WORLD",
    url: "https://reidoscanais.tv/embed/?id=discoveryworld"
  },
  {
    name: "ANIMAL PLANET",
    url: "https://reidoscanais.tv/embed/?id=animalplanet"
  },
  {
    name: "NATIONAL GEOGRAPHIC",
    url: "https://reidoscanais.tv/embed/?id=natgeo"
  },
  {
    name: "HISTORY",
    url: "https://reidoscanais.tv/embed/?id=history"
  },
  {
    name: "HISTORY 2",
    url: "https://reidoscanais.tv/embed/?id=history2"
  },
  // Infantil
  {
    name: "CARTOON NETWORK",
    url: "https://reidoscanais.tv/embed/?id=cartoon"
  },
  {
    name: "NICKELODEON",
    url: "https://reidoscanais.tv/embed/?id=nick"
  },
  {
    name: "DISNEY CHANNEL",
    url: "https://reidoscanais.tv/embed/?id=disney"
  },
  {
    name: "GLOOB",
    url: "https://reidoscanais.tv/embed/?id=gloob"
  },
  {
    name: "BOOMERANG",
    url: "https://reidoscanais.tv/embed/?id=boomerang"
  },
  {
    name: "TOONCAST",
    url: "https://reidoscanais.tv/embed/?id=tooncast"
  },
  // Notícias
  {
    name: "GLOBO NEWS",
    url: "https://reidoscanais.tv/embed/?id=globonews"
  },
  {
    name: "BAND NEWS",
    url: "https://reidoscanais.tv/embed/?id=bandnews"
  },
  {
    name: "RECORD NEWS",
    url: "https://reidoscanais.tv/embed/?id=recordnews"
  },
  // Abertos
  {
    name: "GLOBO SP",
    url: "https://reidoscanais.tv/embed/?id=globosp"
  },
  {
    name: "RECORD",
    url: "https://reidoscanais.tv/embed/?id=record"
  },
  {
    name: "SBT",
    url: "https://reidoscanais.tv/embed/?id=sbt"
  },
  {
    name: "BAND",
    url: "https://reidoscanais.tv/embed/?id=band"
  },
  {
    name: "REDE TV",
    url: "https://reidoscanais.tv/embed/?id=redetv"
  }
];
