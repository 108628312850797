import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { LiveMatch } from './types';
import StreamSelector from './StreamSelector';
import Hls from 'hls.js';

interface VideoPlayerProps {
  match: LiveMatch | null;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ match }) => {
  const [streamUrl, setStreamUrl] = useState<string>('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Previne abertura de novas janelas
  useEffect(() => {
    const preventNewWindow = (e: MessageEvent) => {
      if (e.data === 'openNewWindow') {
        e.preventDefault();
        return false;
      }
    };

    window.addEventListener('message', preventNewWindow);
    return () => window.removeEventListener('message', preventNewWindow);
  }, []);

  const isDirectStream = (url: string) => {
    return url.endsWith('.m3u8');
  };

  useEffect(() => {
    if (!streamUrl || !videoRef.current) return;

    if (isDirectStream(streamUrl)) {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }

      if (Hls.isSupported()) {
        const hls = new Hls({
          xhrSetup: (xhr) => {
            xhr.setRequestHeader('User-Agent', 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36');
            xhr.setRequestHeader('Referer', 'https://livepush.io/');
            const token = localStorage.getItem('@BetMax:token');
            if (token) {
              xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            }
          }
        });

        hls.loadSource(streamUrl);
        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          videoRef.current?.play();
        });

        hlsRef.current = hls;
      } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        // Fallback para Safari que tem suporte nativo a HLS
        videoRef.current.src = streamUrl;
      }
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [streamUrl]);

  return (
    <Box sx={{ 
      flex: 1,
      backgroundColor: '#000', 
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      p: 2,
      height: '100%',
      maxHeight: '500px',
      minHeight: '400px',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <StreamSelector onStreamSelect={setStreamUrl} />
      <Box sx={{ flex: 1, position: 'relative' }}>
        {!streamUrl ? (
          <Box sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white'
          }}>
            <Typography>Selecione um canal para assistir</Typography>
          </Box>
        ) : isDirectStream(streamUrl) ? (
          <video
            ref={videoRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: '#000'
            }}
            controls
            autoPlay
            playsInline
          />
        ) : (
          <Box 
            component="div"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: '#000'
            }}
          >
            <iframe
              ref={iframeRef}
              src={streamUrl}
              style={{
                width: '100%',
                height: '100%',
                border: 'none'
              }}
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              sandbox="allow-same-origin allow-scripts allow-forms"
              onLoad={() => {
                if (iframeRef.current) {
                  try {
                    // Injeta o script que previne popups
                    const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document;
                    if (iframeDoc) {
                      const script = iframeDoc.createElement('script');
                      script.src = '/prevent-popup.js';
                      iframeDoc.head.appendChild(script);
                    }

                    // Tenta injetar código para prevenir window.open
                    const iframeWindow = iframeRef.current.contentWindow;
                    if (iframeWindow) {
                      iframeWindow.postMessage({
                        type: 'PREVENT_NEW_WINDOW',
                        payload: true
                      }, '*');
                    }
                  } catch (error) {
                    console.error('Erro ao tentar prevenir nova janela:', error);
                  }
                }
              }}
            />
          </Box>
        )}
      </Box>
      <Typography variant="caption" sx={{ textAlign: 'center', mt: 2, color: 'white' }}>
        Sua contribuição é fundamental para a manutenção do site. Agradecemos seu apoio.
      </Typography>
    </Box>
  );
};

export default VideoPlayer;
