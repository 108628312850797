import React from 'react';
import { Box, Card, CardContent, Grid, Typography, Chip, CardMedia, Button } from '@mui/material';
import { People as PeopleIcon, AttachMoney as MoneyIcon, Timer as TimerIcon, EmojiEvents } from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './BoloesDisponiveis.css';

interface Premio {
  colocacao: number;
  valor: number;
  porcentagem: number;
}

interface Bolao {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  entryFee: number;
  estimatedPrize: number;
  participants: number;
  maxParticipants: number;
  deadline: string;
  status: 'open' | 'closed' | 'in_progress';
  premios: Premio[];
  rodadas: number;
}

const mockBoloes: Bolao[] = [
  {
    id: 1,
    title: "Apostas Esportivas 2024",
    description: "Faça suas apostas nos principais campeonatos e multiplique suas chances de ganhar!",
    imageUrl: "https://www.mktesportivo.com/wp-content/uploads/2021/10/aposta_esportiva.jpg",
    entryFee: 50,
    estimatedPrize: 5000,
    participants: 45,
    maxParticipants: 100,
    deadline: "2024-03-15",
    status: 'open',
    premios: [
      { colocacao: 1, valor: 2500, porcentagem: 50 },
      { colocacao: 2, valor: 1500, porcentagem: 30 },
      { colocacao: 3, valor: 1000, porcentagem: 20 }
    ],
    rodadas: 8
  },
  {
    id: 2,
    title: "Bolão Premium",
    description: "Participe do nosso bolão premium com as melhores odds e prêmios especiais!",
    imageUrl: "https://medias.itatiaia.com.br/dims4/default/8a2af9b/2147483647/strip/true/crop/388x204+0+6/resize/1200x630!/format/webp/quality/90/?url=https%3A%2F%2Fk2-prod-radio-itatiaia.s3.us-east-1.amazonaws.com%2Fbrightspot%2F7f%2F43%2F987382d247c18eceb11b8dab4c6b%2Ffreepik.JPG",
    entryFee: 100,
    estimatedPrize: 10000,
    participants: 75,
    maxParticipants: 200,
    deadline: "2024-04-01",
    status: 'open',
    premios: [
      { colocacao: 1, valor: 5000, porcentagem: 50 },
      { colocacao: 2, valor: 3000, porcentagem: 30 },
      { colocacao: 3, valor: 2000, porcentagem: 20 }
    ],
    rodadas: 38
  },
  {
    id: 3,
    title: "Super Bolão Futebol",
    description: "O maior bolão de futebol do Brasil! Prêmios incríveis para os vencedores.",
    imageUrl: "https://s2-extra.glbimg.com/Ccu4NS3KXVJefv3fnu_wmpUv4Bo=/0x0:924x520/888x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_1f551ea7087a47f39ead75f64041559a/internal_photos/bs/2023/5/J/azIDKlQ6SqnbB8fNctmg/whatsapp-image-2023-06-06-at-18.51.52.jpeg",
    entryFee: 75,
    estimatedPrize: 7500,
    participants: 120,
    maxParticipants: 150,
    deadline: "2024-02-28",
    status: 'in_progress',
    premios: [
      { colocacao: 1, valor: 3750, porcentagem: 50 },
      { colocacao: 2, valor: 2250, porcentagem: 30 },
      { colocacao: 3, valor: 1500, porcentagem: 20 }
    ],
    rodadas: 13
  }
];

const BoloesDisponiveis: React.FC = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        Bolões Disponíveis
      </Typography>
      
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {mockBoloes.map((bolao) => (
          <SwiperSlide key={bolao.id}>
            <Card 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                },
                position: 'relative',
                overflow: 'hidden'
              }}
            >
              <CardMedia
                component="img"
                height="140"
                image={bolao.imageUrl}
                alt={bolao.title}
                sx={{ objectFit: 'cover' }}
              />
              
              <Box 
                sx={{ 
                  position: 'absolute',
                  top: 12,
                  right: 12,
                  display: 'flex',
                  gap: 1
                }}
              >
                <Chip 
                  label={bolao.status === 'open' ? 'Aberto' : 'Em Andamento'}
                  color={bolao.status === 'open' ? 'success' : 'warning'}
                  size="small"
                />
              </Box>
              
              <CardContent sx={{ flexGrow: 1, pt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {bolao.title}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <MoneyIcon fontSize="small" color="primary" />
                    <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {bolao.entryFee} <span style={{ color: '#FFD700' }}>coins</span>
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                  <PeopleIcon fontSize="small" color="action" />
                  <Typography variant="body2" color="text.secondary">
                    {bolao.participants}/{bolao.maxParticipants} participantes
                  </Typography>
                </Box>
                
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {bolao.description}
                </Typography>

                {/* Prêmios em destaque */}
                <Box className="premios-container">
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      fontWeight: 'bold', 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 1,
                      mb: 2
                    }}
                  >
                    <EmojiEvents color="primary" /> 
                    Premiação Total: R$ {bolao.estimatedPrize.toLocaleString()}
                  </Typography>
                  
                  <Grid container spacing={2}>
                    {bolao.premios.map((premio) => (
                      <Grid item xs={4} key={premio.colocacao}>
                        <div className={`premio-card ${
                          premio.colocacao === 1 ? 'primeiro' : 
                          premio.colocacao === 2 ? 'segundo' : 'terceiro'
                        }`}>
                          <Typography variant="caption" display="block" sx={{ mb: 0.5 }}>
                            {premio.colocacao}º Lugar
                          </Typography>
                          <Typography variant="subtitle2" fontWeight="bold">
                            R$ {premio.valor.toLocaleString()}
                          </Typography>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </CardContent>
              <Box sx={{ p: 2, pt: 0 }}>
                <Button 
                  variant="contained" 
                  fullWidth 
                  sx={{ 
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 'bold'
                  }}
                >
                  Assinar Bolão
                </Button>
              </Box>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default BoloesDisponiveis;
