import React from 'react';
import { Box, Typography, Card } from '@mui/material';
import { LiveMatch } from './types';

interface MatchListProps {
  matches: LiveMatch[];
  onSelectMatch?: (match: LiveMatch) => void;
  selectedMatchId: string | null;
}

const MatchList: React.FC<MatchListProps> = ({ 
  matches, 
  onSelectMatch, 
  selectedMatchId 
}) => {
  if (matches.length === 0) {
    return (
      <Box sx={{ 
        width: '300px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: 'background.paper'
      }}>
        <Box sx={{ 
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          borderRadius: 2,
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url(https://cdn.correiodoestado.com.br/img/c/920/615/dn_arquivo/2024/05/image-bola-no-campo.jpeg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'brightness(0.7)',
            zIndex: 0
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.8) 100%)',
            zIndex: 1
          }
        }}>
          <Box sx={{
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            p: 3,
          }}>
            <Typography
              variant="h6"
              sx={{ 
                color: 'white',
                textAlign: 'center',
                fontWeight: 600,
                textShadow: '0 2px 4px rgba(0,0,0,0.3)'
              }}
            >
              Nenhum jogo disponível
            </Typography>
            <Typography
              variant="body2"
              sx={{ 
                color: 'rgba(255,255,255,0.9)',
                textAlign: 'center',
                maxWidth: '80%',
                textShadow: '0 1px 2px rgba(0,0,0,0.3)'
              }}
            >
              Fique ligado! Novos jogos serão adicionados em breve.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      width: '300px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      backgroundColor: 'background.paper'
    }}>
      {/* Cabeçalho fixo */}
      <Box sx={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: 'background.paper',
        borderBottom: '1px solid',
        borderColor: 'divider',
        px: 2,
        py: 1.5,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}>
        <Box sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <Typography sx={{
            fontSize: '0.9rem',
            fontWeight: 600,
            color: 'text.primary',
          }}>
            {matches[0]?.league}
          </Typography>
          <Typography sx={{
            fontSize: '0.9rem',
            fontWeight: 500,
            color: 'text.secondary',
          }}>
            {matches[0]?.year}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <Box sx={{
            width: 28,
            height: 28,
            borderRadius: '50%',
            backgroundColor: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '0.8rem',
            fontWeight: 'bold'
          }}>
            {matches[0]?.round}
          </Box>
          <Typography sx={{
            fontSize: '0.8rem',
            fontWeight: 500,
            color: 'text.secondary'
          }}>
            Rodada
          </Typography>
        </Box>
      </Box>

      {/* Lista de jogos com scroll */}
      <Box sx={{ 
        flex: 1,
        overflowY: 'auto',
        pr: 1,
        pl: 1,
        pt: 2,
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 2,
          pb: 2
        }}>
          {matches.map((match) => (
            <Card 
              key={match.id}
              sx={{ 
                p: 1.5,
                cursor: 'pointer',
                backgroundColor: 'background.paper',
                transition: 'all 0.2s',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                  transform: 'translateY(-2px)',
                  boxShadow: 2
                }
              }}
              onClick={() => onSelectMatch?.(match)}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2
              }}>
                {/* Time Casa */}
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: 1
                }}>
                  <Box sx={{
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor: '#f8f9fa',
                    mb: 0.5,
                    overflow: 'hidden'
                  }}>
                    <img 
                      src={match.homeTeam.logo} 
                      alt={match.homeTeam.name} 
                      style={{ 
                        width: 30,
                        height: 30,
                        objectFit: 'contain'
                      }} 
                    />
                  </Box>
                  <Typography sx={{ 
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    textAlign: 'center'
                  }}>
                    {match.homeTeam.name}
                  </Typography>
                </Box>

                {/* VS e Status */}
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 0.5
                }}>
                  {match.status === 'em_andamento' || match.homeScore !== null ? (
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      mb: 0.5
                    }}>
                      <Typography sx={{ 
                        fontSize: '1rem',
                        fontWeight: 600,
                        color: 'text.primary',
                      }}>
                        {match.homeScore ?? 0}
                      </Typography>
                      <Typography sx={{ 
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        color: 'text.secondary',
                      }}>
                        x
                      </Typography>
                      <Typography sx={{ 
                        fontSize: '1rem',
                        fontWeight: 600,
                        color: 'text.primary',
                      }}>
                        {match.awayScore ?? 0}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ 
                      fontSize: '0.9rem',
                      fontWeight: 600,
                      color: 'text.secondary',
                      mb: 0.5
                    }}>
                      VS
                    </Typography>
                  )}
                  <Typography sx={{ 
                    fontSize: '0.65rem',
                    fontWeight: 500,
                    color: match.status === 'em_andamento' ? 'error.main' : '#777777',
                    textTransform: 'uppercase',
                    letterSpacing: '0.4px'
                  }}>
                    {match.status === 'em_andamento' ? 'Ao Vivo' : match.status}
                  </Typography>
                </Box>

                {/* Time Visitante */}
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: 1
                }}>
                  <Box sx={{
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor: '#f8f9fa',
                    mb: 0.5,
                    overflow: 'hidden'
                  }}>
                    <img 
                      src={match.awayTeam.logo} 
                      alt={match.awayTeam.name} 
                      style={{ 
                        width: 30,
                        height: 30,
                        objectFit: 'contain'
                      }} 
                    />
                  </Box>
                  <Typography sx={{ 
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    textAlign: 'center'
                  }}>
                    {match.awayTeam.name}
                  </Typography>
                </Box>
              </Box>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MatchList;
