import React, { useState } from 'react';
import { Box, Grid, Paper, Typography, Tab, Tabs, IconButton, Avatar, Badge } from '@mui/material';
import TopMenu from './TopMenu';
import Games from './Games';
import Ranking from './Ranking';
import WatchLive from './WatchLive';
import MyBets from './MyBets';
import { SportsSoccer, EmojiEvents, Timeline, Notifications, LiveTv, Stadium } from '@mui/icons-material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: '100%' }}
    >
      {value === index && (
        <Box sx={{ height: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Dashboard = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      bgcolor: '#f8f9fa'
    }}>
      <TopMenu />
      
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 8,
          px: 3,
          py: 2,
        }}
      >
        <Grid container spacing={3}>
          {/* Left Sidebar */}
          <Grid item xs={12} md={3}>
            <Paper 
              sx={{ 
                p: 2,
                borderRadius: 2,
                background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
                color: 'white',
                mb: 2,
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'radial-gradient(circle at top right, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 60%)',
                },
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                  <Avatar 
                    sx={{ 
                      width: 48, 
                      height: 48,
                      bgcolor: 'rgba(255,255,255,0.2)',
                      border: '2px solid rgba(255,255,255,0.3)',
                      fontSize: '1.2rem',
                      fontWeight: 500
                    }}
                  >
                    M
                  </Avatar>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.1rem', mb: 0.5 }}>
                      Marcela Silva
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8, fontSize: '0.85rem' }}>
                      Premium Member
                    </Typography>
                  </Box>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box 
                      sx={{ 
                        textAlign: 'center',
                        py: 1,
                        px: 1.5,
                        bgcolor: 'rgba(255,255,255,0.1)',
                        borderRadius: 2,
                        transition: 'all 0.2s',
                        '&:hover': {
                          bgcolor: 'rgba(255,255,255,0.15)',
                          transform: 'translateY(-2px)'
                        }
                      }}
                    >
                      <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '1.8rem', mb: 0.5 }}>
                        12
                      </Typography>
                      <Typography variant="body2" sx={{ opacity: 0.8, fontSize: '0.8rem' }}>
                        Apostas
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box 
                      sx={{ 
                        textAlign: 'center',
                        py: 1,
                        px: 1.5,
                        bgcolor: 'rgba(255,255,255,0.1)',
                        borderRadius: 2,
                        transition: 'all 0.2s',
                        '&:hover': {
                          bgcolor: 'rgba(255,255,255,0.15)',
                          transform: 'translateY(-2px)'
                        }
                      }}
                    >
                      <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '1.8rem', mb: 0.5 }}>
                        3
                      </Typography>
                      <Typography variant="body2" sx={{ opacity: 0.8, fontSize: '0.8rem' }}>
                        Vitórias
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Paper sx={{ p: 2, borderRadius: 2 }}>
              <Box sx={{ mb: 2 }}>
                <Ranking showOnlyTop5={true} />
              </Box>
            </Paper>
          </Grid>

          {/* Main Content */}
          <Grid item xs={12} md={9}>
            <Paper sx={{ 
              borderRadius: 2,
              overflow: 'hidden',
              height: 'calc(100vh - 100px)',
              display: 'flex',
              flexDirection: 'column'
            }}>
              {/* Tabs */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'white' }}>
                <Tabs 
                  value={tabValue} 
                  onChange={handleTabChange}
                  sx={{
                    px: 2,
                    '& .MuiTab-root': {
                      minHeight: '60px',
                      textTransform: 'none',
                    }
                  }}
                >
                  <Tab 
                    icon={<LiveTv />} 
                    label="Assistir ao Vivo" 
                    iconPosition="start"
                    sx={{ gap: 1 }}
                  />
                  <Tab 
                    icon={<Stadium />} 
                    label="Partidas" 
                    iconPosition="start"
                    sx={{ gap: 1 }}
                  />
                  <Tab 
                    icon={<EmojiEvents />} 
                    label="Classificação" 
                    iconPosition="start"
                    sx={{ gap: 1 }}
                  />
                  <Tab 
                    icon={<SportsSoccer />} 
                    label="Minhas Apostas" 
                    iconPosition="start"
                    sx={{ gap: 1 }}
                  />
                </Tabs>
              </Box>

              {/* Tab Panels */}
              <Box sx={{ flex: 1, overflow: 'hidden' }}>
                <TabPanel value={tabValue} index={0}>
                  <WatchLive />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Games showAllGames={true} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <Typography variant="h6">Classificação</Typography>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <MyBets />
                </TabPanel>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
